import React from "react"

import SEO from "@components/seo"
import * as data from "@data/pledge"
import Layout from "@components/layout"
import DWInfoForm from "@components/form_dw_personal_info"
import HeaderAndText from "@components/header_with_text"
import BackButton from "@components/back_button"
import ProgressBar from "@components/_atoms/progress_bar_reg"

const InvestPersonalInfoPage = () => {
  const { dwPersonal } = data
  return (
    <Layout pageClass="pledge" type="gray">
      <SEO title={dwPersonal.title} />
      <ProgressBar />
      <BackButton data={dwPersonal.backLink} />
      <HeaderAndText data={dwPersonal.heading} />
      <DWInfoForm />
    </Layout>
  )
}

export default InvestPersonalInfoPage
