import React, { Component } from "react"
import axios from "axios"
import * as headers from "@data/headers"
import "./style.scss"
import FormButton from "@components/_atoms/button_form_input_submit"
import InputLabelState from "@components/_molecules/input_label_state"
import GeneralSubmitMessage from "@components/_atoms/general_submit_message"
import * as data from "@data/pledge"
import { validate } from "@data/validations"
import * as progress from "@services/progress"
import * as errors from "@services/errors"
import InputPlaces from "@components/_molecules/input_places"


class DWInfoForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      streetaddress: "",
      addressErrorText: "Missing Address",
      addressErrorStatus: false,
      apt: "",
      aptErrorText: "Missing Apt",
      aptErrorStatus: false,
      city: "",
      cityErrorText: "Missing City",
      cityErrorStatus: false,
      state: "",
      stateErrorText: "Missing State",
      stateErrorStatus: false,
      zipcode: "",
      zipCodeErrorText: "Missing Zip Code",
      zipCodeErrorStatus: false,
      generalError: null,
    }
  }

  componentDidMount() {
    let { parentAddress } = localStorage
    if (typeof parentAddress !== `undefined`) {
      let addrObj = JSON.parse(parentAddress)
      this.setState({
        streetaddress: addrObj.address1 || ``,
        apt: addrObj.address2 || ``,
        city: addrObj.city || ``,
        state: addrObj.province || ``,
        zipcode: addrObj.postalCode || ``,
      })
    }
  }
  handlePlacesChange = (state) => {
    if (state.city) {
      this.setState({
        state: state.state,
        city: state.city,
        zipcode: state.zipcode,
        streetaddress: state.streetaddress,
      })
    } else {
      var addressValue = document.getElementById("Address").value
      this.setState({ streetaddress: addressValue })
    }
  }
  handleChange = (event) => {
    const target = event.target,
      value = target.value,
      name = target.name

    this.setState({
      [name]: value,
      addressErrorStatus: "",
      cityErrorStatus: "",
      stateErrorStatus: "",
      zipCodeErrorStatus: "",
    })
  }
  handleSubmit = (ev) => {
    ev.preventDefault()
    let _this = this,
      addressErrorStatus = this.state.streetaddress === "",
      cityErrorStatus = this.state.city === "",
      stateErrorStatus = !validate.state.test(this.state.state),
      zipCodeErrorStatus = !validate.zipCode.test(this.state.zipcode)
    this.setState({
      addressErrorStatus,
      cityErrorStatus,
      stateErrorStatus,
      zipCodeErrorStatus,
    })
  }

  errorHandler = (err) => {
    this.setState({
      buttonSubmitted: false,
      generalError: errors.messaging(err),
    })
  }

  render() {
    const { dwPersonal } = data
    return (
      <div className="dw-info-form">
        <form onSubmit={this.handleSubmit}>
          <div className="side-by-side">
            <InputPlaces handlePlacesChange={this.handlePlacesChange} />
            <div>
              <InputLabelState
                data={{
                  name: `Apt`,
                  type: `text`,
                  helpText: this.state.aptErrorText,
                  errorStatus: this.state.aptErrorStatus,
                  defaultVisibility: 0,
                  value: this.state.apt,
                }}
                onChange={this.handleChange}
              />
            </div>
          </div>
          <InputLabelState
            data={{
              name: `City`,
              type: `text`,
              helpText: this.state.cityErrorText,
              errorStatus: this.state.cityErrorStatus,
              defaultVisibility: 0,
              value: this.state.city,
            }}
            onChange={this.handleChange}
          />
          <div className="side-by-side">
            <InputLabelState
              data={{
                name: `State`,
                type: `text`,
                value: this.state.state,
                selectedValue: ``,
                defaultVisibility: 0,
                errorStatus: this.state.stateErrorStatus,
                helpText: this.state.stateErrorText,
              }}
              onChange={this.handleChange}
            />
            <InputLabelState
              data={{
                name: `Zip Code`,
                type: `text`,
                value: this.state.zipcode,
                selectedValue: ``,
                defaultVisibility: 0,
                errorStatus: this.state.zipCodeErrorStatus,
                helpText: this.state.zipCodeErrorText,
              }}
              onChange={this.handleChange}
            />
          </div>
          <GeneralSubmitMessage error={this.state.generalError} />
          <FormButton />
        </form>
      </div>
    )
  }
}

export default DWInfoForm
